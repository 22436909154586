import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { track } from "@amplitude/analytics-browser";

import _ from "lodash";

import moment from "moment";

import { Grid, Stack, Box, Skeleton } from "@mui/material";

import { IUserCourse } from "../../types/course";
import {
  Chip,
  IconButton,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";

import Chevron from "../../components/icons/Chevron";
import { Link } from "react-router-dom";
import Calendar from "../../components/icons/Calendar";
import Hash from "../../components/icons/Hash";
import CircleFilled from "../../components/icons/CircleFilled";

import LessonCard from "../../components/LessonCard";
import { useParams } from "react-router-dom";
import {
  COURSE_TRAINING_TYPE_LIST,
  CourseTrainingType,
} from "../../constants/courseTrainingType";
import { app, authentication } from "@microsoft/teams-js";
import axios from "axios";
import ArchDiagram from "../../components/Arch";

export default function Course() {
  const { courseId, courseTrainingType } = useParams<{
    courseId?: string;
    courseTrainingType?: string;
  }>();

  const { t } = useTranslation();

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState(false);

  const [userCourse, setUserCourse] = useState<IUserCourse>();

  const loadData = useCallback(() => {
    authentication
      .getAuthToken()
      .then((token) => {
        axios
          .get<IUserCourse>(`/Courses/GetUserCourseLessons`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              courseId: courseId,
              courseTrainingType: courseTrainingType,
            },
          })
          .then((res) => {
            setUserCourse(res.data);
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err:", err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, [loadData]);

  useEffect(() => {
    track("OpenCourse");
    app.notifyAppLoaded();
  }, []);

  return (
    <Stack style={{ paddingBottom: 16, minHeight: "90vh" }} spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Link
          to="/courses"
          state={{
            back: true,
          }}
        >
          <IconButton>
            <Chevron
              style={{
                transform: "rotate(270deg)",
              }}
            />
          </IconButton>
        </Link>
        {isLoading ? (
          <Skeleton width={200} height={58} />
        ) : (
          <Typography variant="h2" component="h1">
            {userCourse?.courseName}
          </Typography>
        )}
      </Stack>

      {isLoading ? (
        <Stack alignItems="center" justifyContent="center" flex={1}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Box>
            <Grid container>
              <Grid item md={8}>
                <Typography variant="h5" component="h2">
                  {t("texts.statistics", "Statistics")}
                </Typography>
                <Paper
                  style={{
                    border: "none",
                    padding: 24,
                  }}
                  elevation={0}
                >
                  <Stack direction="row">
                    <Stack flex={1} spacing={1}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {userCourse && (
                          <Chip
                            label={t(
                              COURSE_TRAINING_TYPE_LIST[
                                userCourse.courseTrainingType
                              ].name
                            )}
                            style={{
                              backgroundColor:
                                COURSE_TRAINING_TYPE_LIST[
                                  userCourse.courseTrainingType
                                ].color,
                            }}
                          />
                        )}
                        <Typography>
                          {t("texts.training", "training")}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Calendar width={48} height={48} />
                        <Typography>
                          {moment.utc(userCourse?.startDate).format("L")}
                          {userCourse?.endDate && (
                            <>
                              {" "}
                              - {moment.utc(userCourse?.endDate).format("L")}
                            </>
                          )}
                        </Typography>
                      </Stack>
                      {userCourse?.courseTrainingType ===
                        CourseTrainingType.Instant && (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Hash width={48} height={48} />
                          <Typography>
                            {userCourse?.lessons.length}{" "}
                            {t("texts.lessons", "lessons")}
                          </Typography>
                        </Stack>
                      )}
                      <Stack direction="row" spacing={1} alignItems="center">
                        {userCourse?.active ? (
                          <>
                            <CircleFilled color="green" />
                            <Typography>
                              {userCourse.active
                                ? t("status.active")
                                : t("status.finished")}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CircleFilled color="red" />
                            <Typography>{t("status.finished")}</Typography>
                          </>
                        )}
                      </Stack>
                    </Stack>
                    <Stack alignItems="center" flex={1} spacing={2}>
                      {userCourse && (
                        <Stack
                          style={{
                            position: "relative",
                          }}
                        >
                          <ArchDiagram
                            primaryText={`${userCourse.lessonsCorrect} / ${userCourse.lessonsAnswered}`}
                            secondaryText={
                              userCourse?.courseTrainingType !==
                              CourseTrainingType.Regular
                                ? userCourse.courseTrainingType ===
                                  CourseTrainingType.Mandatory
                                  ? `Goal: ${userCourse.answersGivenCriteria}%`
                                  : `Goal: ${userCourse?.correctAnswersCriteriaCount}/${userCourse.lessonsSent}`
                                : undefined
                            }
                            color={
                              userCourse?.courseTrainingType ===
                              CourseTrainingType.Regular
                                ? "default"
                                : userCourse.lessonsCorrect >=
                                  userCourse.correctAnswersCriteriaCount
                                ? "success"
                                : "error"
                            }
                            completion={
                              (userCourse.lessonsCorrect /
                                userCourse.lessonsAnswered) *
                              85
                            }
                          />
                        </Stack>
                      )}
                      <Typography
                        style={{
                          color: "#78716c",
                        }}
                      >
                        {t(
                          "texts.courseProgress",
                          "Successfully completed lessons"
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography variant="h5" component="h2">
              {t("texts.lessons", "Lessons")}
            </Typography>
            <Grid container spacing={4}>
              {_.zip(
                ..._.chunk(userCourse?.lessons, smDown ? 1 : mdDown ? 2 : 3)
              ).map((column, index) => (
                <Grid
                  key={index}
                  container
                  item
                  xs
                  spacing={4}
                  direction="column"
                  role="column"
                >
                  {column.map((lesson) =>
                    lesson ? (
                      <Grid key={lesson.quizId} item>
                        <LessonCard
                          lesson={lesson}
                          courseTrainingType={userCourse?.courseTrainingType}
                          callback={() => {
                            loadData();
                          }}
                        />
                      </Grid>
                    ) : null
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Stack>
  );
}
