import { useEffect, useState } from "react";
import { authentication, getContext } from "@microsoft/teams-js";

import { useTranslation } from "react-i18next";
import axios from "axios";
import { IChannel, IChannelsResponse } from "../types/channel";

import { app } from "@microsoft/teams-js";

import { track } from "@amplitude/analytics-browser";
import {
  Avatar,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import {
  IBadge,
  ILeaderboardResponse,
  ILeaderboardScore,
  ITimestamps,
} from "../types/leaderboard";

import Chevron from "../components/icons/Chevron";
import { DataGrid } from "@mui/x-data-grid";
import { Person } from "@microsoft/mgt-react";
import { AvatarGroup } from "@material-ui/lab";
import _ from "lodash";

const badgesToShow = 3;

export default function Tab() {
  const { t } = useTranslation();

  const [isChannelsLoading, setIsChannelsLoading] = useState(true);
  const [currentChannel, setCurrentChannel] = useState<string>();
  const [channels, setChannels] = useState<IChannel[]>([]);

  const [isPrivate, setIsPrivate] = useState(true);

  const [date, setDate] = useState<Date>(moment().toDate());
  const [dateRange, setDateRange] = useState<ITimestamps>({
    timestampMin: moment().toDate(),
    timestampMax: moment().toDate(),
  });

  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState<ILeaderboardScore[]>([]);
  const [userObjectId, setUserObjectId] = useState<string | undefined>();

  useEffect(() => {
    app.getContext().then((context) => {
      setUserObjectId(context.user?.id);

      track(context.channel ? context.channel.id : "OpenLeaderboardTab");
      if (context.channel) {
        setIsPrivate(true);
        setCurrentChannel(context.channel.id);
      } else {
        setIsPrivate(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!isPrivate) {
      authentication
        .getAuthToken()
        .then((token) => {
          axios
            .get<IChannelsResponse>("channels/getUserChannels", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: { userObjectId: userObjectId },
            })
            .then((res) => {
              setChannels(res.data.channels);
              setIsChannelsLoading(false);
            })
            .catch((err) => {
              setIsChannelsLoading(false);
              console.log("err:", err);
            });
        })
        .catch((err) => {
          setIsChannelsLoading(false);
          console.log("err:", err);
        });
    }
  }, [isPrivate]);

  useEffect(() => {
    if (channels[0]) {
      setCurrentChannel(channels[0].teamsChannelId);
    } else {
      setCurrentChannel(undefined);
    }
  }, [channels]);

  useEffect(() => {
    setItems([]);
    if (!currentChannel) {
      return;
    }
    setLoading(true);
    authentication
      .getAuthToken()
      .then((token) => {
        axios
          .get<ILeaderboardResponse>(`Leaderboard/GetLeaderboard`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              channelId: currentChannel,
              LeaderboardTimestamp: date,
            },
          })
          .then((res) => {
            setLoading(false);
            setItems(res.data.leaderboard);
          })
          .catch((err) => {
            setLoading(false);
            console.log("err:", err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log("err:", err);
      });
  }, [currentChannel, date]);

  useEffect(() => {
    if (!currentChannel) {
      return;
    }

    authentication
      .getAuthToken()
      .then((token) => {
        axios
          .get<ITimestamps>(`Leaderboard/GetTimestamps`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              channelId: currentChannel,
            },
          })
          .then((res) => {
            setDateRange({
              timestampMin: moment(res.data.timestampMin).toDate(),
              timestampMax: moment(res.data.timestampMax).toDate(),
            });
            setDate(moment(res.data.timestampMax).toDate());
          })
          .catch((err) => {
            setLoading(false);
            console.log("err:", err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log("err:", err);
      });
  }, [currentChannel]);

  useEffect(() => {
    app.notifyAppLoaded();
  }, []);

  return (
    <div>
      <Grid
        container
        alignItems="center"
        spacing={2}
        style={{ paddingBottom: 16 }}
      >
        {isPrivate ? (
          <Grid item>
            <Typography variant="h2" component="h1">
              {t("texts.monthlyTeamLeaderboard")}
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Typography variant="h2" component="h1">
                {t("texts.monthlyLeaderboard")}
              </Typography>
            </Grid>
            <Grid item>
              {channels.length > 0 && (
                <Select
                  value={currentChannel || ""}
                  onChange={(event) => {
                    setCurrentChannel(event.target.value as string);
                  }}
                  variant="filled"
                  disableUnderline
                >
                  {channels.map((channel) => (
                    <MenuItem key={channel.id} value={channel.teamsChannelId}>
                      {channel.teamName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
          </>
        )}
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography>{t("texts.dateRange")}:</Typography>
            </Grid>
            <Grid item>
              <IconButton
                title="Previous"
                disabled={moment(dateRange.timestampMin).isSame(date, "month")}
                onClick={() =>
                  setDate((e) => moment(e).subtract(1, "month").toDate())
                }
              >
                <Chevron
                  style={{
                    transform: "rotate(270deg)",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography>
                {moment(
                  moment(date).startOf("month").isAfter(dateRange.timestampMin)
                    ? moment(date).startOf("month")
                    : dateRange.timestampMin
                ).format("ll")}{" "}
                -{" "}
                {moment(
                  moment(date).endOf("month").isBefore(dateRange.timestampMax)
                    ? moment(date).endOf("month")
                    : dateRange.timestampMax
                ).format("ll")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                title="Next"
                disabled={moment(dateRange.timestampMax).isSame(date, "month")}
                onClick={() =>
                  setDate((e) => moment(e).add(1, "month").toDate())
                }
              >
                <Chevron
                  style={{
                    transform: "rotate(90deg)",
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DataGrid
        getRowId={(row) => row.aadObjectId}
        rows={items}
        columns={[
          {
            field: "rank",
            headerName: t("leaderboardTable.place"),
            minWidth: 100,
            sortable: false,
          },
          {
            field: "displayName",
            headerName: t("leaderboardTable.name"),
            minWidth: 300,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
              const badgeCount = params.row.badgeList.length;

              return (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Person userId={params.row.aadObjectId} />
                  </Grid>
                  <Grid item>{params.row.displayName}</Grid>
                  <Grid item>
                    <AvatarGroup>
                      {_.slice<IBadge>(
                        params.row.badgeList,
                        0,
                        badgeCount > badgesToShow
                          ? badgesToShow - 1
                          : badgeCount
                      ).map((badge: IBadge, index) => (
                        <Tooltip
                          arrow
                          key={badge.badgeId}
                          title={
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Avatar
                                  alt={badge.badgeName}
                                  src={badge.badgeUrl}
                                />
                              </Grid>
                              <Grid item>
                                <Typography variant="caption">
                                  {badge.badgeName}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        >
                          <Avatar
                            style={{
                              width: 24,
                              height: 24,
                              zIndex: 100 - index,
                              marginLeft: -5,
                            }}
                            alt={badge.badgeName}
                            src={badge.badgeUrl}
                          />
                        </Tooltip>
                      ))}
                      {badgeCount > badgesToShow ? (
                        <Tooltip
                          arrow
                          title={
                            <div>
                              {_.slice<IBadge>(
                                params.row.badgeList,
                                badgesToShow - 1
                              ).map((badge: IBadge) => (
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item>
                                    <Avatar
                                      alt={badge.badgeName}
                                      src={badge.badgeUrl}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="caption">
                                      {badge.badgeName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ))}
                            </div>
                          }
                        >
                          <Avatar
                            style={{
                              width: 24,
                              height: 24,
                              fontSize: 9,
                              marginLeft: -5,
                            }}
                          >
                            +{badgeCount - badgesToShow + 1}
                          </Avatar>
                        </Tooltip>
                      ) : null}
                    </AvatarGroup>
                  </Grid>
                </Grid>
              );
            },
          },
          {
            field: "points",
            headerName: t("leaderboardTable.points"),
            minWidth: 110,
            sortable: false,
          },
          {
            field: "answeredQuestions",
            headerName: t("leaderboardTable.questionsAnswered"),
            minWidth: 260,
            sortable: false,
          },
          {
            field: "answeredQuizzes",
            headerName: t("leaderboardTable.quizzesCompleted"),
            minWidth: 245,
            sortable: false,
          },
          {
            field: "answeredQuizzesCorrectly",
            headerName: t("leaderboardTable.quizzesGuessed"),
            minWidth: 230,
            sortable: false,
          },
        ]}
        rowsPerPageOptions={[100]}
        disableSelectionOnClick
        autoHeight
        disableColumnMenu
      />
    </div>
  );
}
