import { useEffect, useState } from "react";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { track } from "@amplitude/analytics-browser";

import _ from "lodash";

import { Grid, Stack, FormControlLabel, Switch, Box } from "@mui/material";
import CourseCard from "../../components/CourseCard";
import { IUserCourse } from "../../types/course";
import { CircularProgress, Typography } from "@material-ui/core";

import { app, authentication } from "@microsoft/teams-js";
import axios from "axios";
import { CourseTrainingType } from "../../constants/courseTrainingType";

export default function MyCourses() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [showOnlyActive, setShowOnlyActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [userCourses, setUserCourses] = useState<IUserCourse[]>([]);

  useEffect(() => {
    setUserCourses([]);
    setIsLoading(true);
    authentication
      .getAuthToken()
      .then((token) => {
        axios
          .get<IUserCourse[]>(`/Courses/GetUserCourses`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setUserCourses(res.data);
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err:", err);
      });
  }, []);

  useEffect(() => {
    app.getContext().then((context) => {
      if (context.page.subPageId && !location.state?.back) {
        navigate(
          `/courses/${context.page.subPageId}/${CourseTrainingType.Instant}`
        );
      }
    });
  }, []);

  useEffect(() => {
    track("OpenMyCourses");
    app.notifyAppLoaded();
  }, []);

  return (
    <Stack style={{ paddingBottom: 16 }} spacing={2}>
      <Typography variant="h2" component="h1">
        {t("texts.myCourses", "My courses")}
      </Typography>

      <Box>
        <FormControlLabel
          control={
            <Switch
              id="showOnlyActive"
              color="warning"
              checked={showOnlyActive}
              onChange={() => {
                setShowOnlyActive(!showOnlyActive);
              }}
            />
          }
          label={t("texts.showOnlyActive", "See active courses only")}
        />
      </Box>

      <Box>
        {isLoading ? (
          <Stack alignItems="center" justifyContent="center" flex={1}>
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container spacing={2}>
            {userCourses
              .filter((item) =>
                showOnlyActive === true ? item.active === true : true
              )
              .map((item) => (
                <Grid
                  key={`${item.courseId}-${item.courseTrainingType}`}
                  item
                  xs={4}
                  md={3}
                  lg={2}
                  flexGrow={1}
                >
                  <RouterLink
                    to={`/courses/${item.courseId}/${item.courseTrainingType}`}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <CourseCard item={item} />
                  </RouterLink>
                </Grid>
              ))}
          </Grid>
        )}
      </Box>
    </Stack>
  );
}
